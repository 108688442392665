import { type Configuration, createPostingApp, getPostings } from '@praktikumio/postings';
import { SupportedLocale } from '@stafftastic/api';
import type { PostingEmbed } from '@stafftastic/api/public';
import { type App, type Ref, computed, ref } from 'vue';

import fontsCssURL from './styles/fonts.scss?url';
import appCssURL from './styles/postings.scss?url';
import tokensCssURL from './styles/tokens.scss?url';
import {
  appendStylesheetToDocument,
  getAttributesFromScript,
  getCurrentScript,
  isSupportedLocale,
  prependStylesheetToShadowRoot,
  showRuntimeError,
  showRuntimeWarning,
} from './utils';

const outletAttribute = 'data-praktikumio-postings';
const knownAttributes = ['embed-id', 'locale'] as const;

type Outlet = Element;

const getOutlets = () => {
  const outlets = [
    ...(document.querySelectorAll(`[${outletAttribute}]`) ?? []),
  ];

  const [catchAll, others] = outlets.reduce<[Outlet | null, Outlet[]]>(
    ([special, others], outlet) => {
      const dataAttr = outlet.getAttribute(outletAttribute);
      return dataAttr === '*'
        ? [outlet, others]
        : [special, [...others, outlet]];
    },
    [null, []] as [Outlet | null, Outlet[]],
  );

  const outletsWithIds = others
    .map((outlet) => ({
      outlet,
      posting_ids: outlet.getAttribute(outletAttribute)?.split(',').map((id) => id.trim()),
    }))
    .filter((outletConfig) => outletConfig.posting_ids) as { outlet: Outlet; posting_ids: string[] }[];

  return { catchAll, outletsWithIds };
};

type AppFactory = (config: Configuration, modalHost: Element) => App<Element>;

const mountApp = (createApp: AppFactory, target: Element, config: Configuration) => {
  const container = document.createElement('div');
  const shadowRoot = container.attachShadow({ mode: 'open' });
  prependStylesheetToShadowRoot(shadowRoot, appCssURL);
  prependStylesheetToShadowRoot(shadowRoot, tokensCssURL);

  const modalHost = document.createElement('div');
  modalHost.setAttribute('id', 'modals');
  shadowRoot.appendChild(modalHost);

  const appHost = document.createElement('div');
  shadowRoot.appendChild(appHost);

  const app = createApp(config, modalHost);
  app.mount(appHost);

  target.appendChild(container);
  return () => app.unmount();
};

const mountPostings = (postings: Ref<PostingEmbed['postings']>, loading: Ref<boolean>, locale: SupportedLocale) => {
  const { catchAll, outletsWithIds } = getOutlets();

  const definedIds = outletsWithIds.flatMap((outlet) => outlet.posting_ids);

  outletsWithIds.forEach(({ outlet, posting_ids }) => {
    mountApp(createPostingApp, outlet, {
      locale,
      app: {
        postings: computed(
          () => postings.value
            .filter((posting) => posting_ids.includes(posting.id))
            .sort((a, b) => posting_ids.indexOf(a.id) - posting_ids.indexOf(b.id)),
        ),
        loading,
      },
    });
  });

  if (catchAll) {
    mountApp(createPostingApp, catchAll, {
      locale,
      app: {
        postings: computed(() => postings.value.filter((posting) => !definedIds.includes(posting.id))),
        loading,
      },
    });
  } else {
    showRuntimeWarning('No catch-all outlet found, it is considered good practice to define a catch-all.');
  }
};

const init = async () => {
  const currentScript = getCurrentScript('script[data-embed-id]');
  if (currentScript) {
    const { locale, 'embed-id': embedId } = getAttributesFromScript(
      currentScript,
      [...knownAttributes],
    );

    if (!embedId) {
      return showRuntimeError('Missing "data-embed-id" attribute');
    }

    if (locale && !isSupportedLocale(locale)) {
      showRuntimeWarning(
        `Invalid locale "${locale}". Using defaulting to "de".`,
      );
    }

    const supportedLocale = locale && isSupportedLocale(locale) ? locale : SupportedLocale.DE;
    appendStylesheetToDocument(fontsCssURL);

    const results = ref<PostingEmbed['postings']>([]);
    const loading = ref(true);

    mountPostings(results, loading, supportedLocale);

    try {
      const postings = await getPostings(embedId);
      loading.value = false;
      results.value = postings;
    } catch (e) {
      showRuntimeError('Failed to fetch postings data');
      if (e instanceof Error) {
        showRuntimeError(e.message);
      }
    }
  }
};

init();
